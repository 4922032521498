import React from 'react';
import "./App.css";

function App() {
  return (
    <>
      <header className='py-4 px-2'>
        <div>
          <a href="https://magcloudsolutions.in" target='_blank' rel='noreferrer' ><img src="favicon.ico" alt="Mag Cloud Solutions" width={100} /></a>
        </div>

        <div className='center'>
          <h1 className='fw-bold'>Welcome to Mag Cloud Trainings</h1>
          <h2 className='fw-semibold'>Your Path to a Better Career</h2>
          <h3><span className='header-span'> A full stack developer covers both the front-end and back-end stages of web and mobile development. </span></h3>
          <h3><span className='header-span'> Kickstart your tech career and learn full stack development with Mag Cloud Trainings. </span></h3>
        </div>
      </header>

      <section className='py-4 fs-overview d-flex'>
        <div className='main-img d-flex center'>
          <h2 className='text-center'>What is full stack development?</h2>
        </div>

        <div>
          <p>In an increasingly crowded digital world, companies need smart web design and development to stand out. Businesses use websites and applications for critical functions including increasing brand awareness, educating potential customers about their products and services, and facilitating sales.</p>

          <p>
            But what is web development? While the internet has been around for decades, it didn't pick up speed until the dot-com burst in year 2000, when a new digital age emerged and took web programming to the next level. Since then, developers have incorporated more visual, communicative, and interactive features into their websites.
          </p>

          <p>
            Websites consist of multiple components: design, function, content, and structure. Front-end and back-end development ensure these elements work together and present users with an engaging, easy to use website. While front-end development focuses on creating the user interface, or the more visual site elements, the back end makes up an infrastructure with systems like API (application programming interface), servers, and databases.
          </p>

          <p>
            Full stack coding requires a blend of both front- and back-end knowledge. A full stack developer builds an application, site, or software from beginning to end, using both sides of the development process. To be successful, they must have extensive knowledge and experience navigating multiple programming languages and frameworks.
          </p>
        </div>


      </section>

      <section className='py-4 details-section bg-grey'>
        <h3 className='text-center'>Why you can trust on us :</h3>

        <div className='card-gallery'>
          <div className="card">
            <img src="assets/expert.png" className="card-img-top" alt="Experienced Mentors" />
            <h5 className="card-title">Experienced Mentors</h5>
          </div>

          <div className="card">
            <img src="assets/beginner.png" className="card-img-top" alt="Beginner Friendly" />
            <h5 className="card-title">Beginner Friendly</h5>
          </div>

          <div className="card">
            <img src="assets/results.png" className="card-img-top" alt="Results Oriented" />
            <h5 className="card-title">Results Oriented</h5>
          </div>

          <div className="card">
            <img src="assets/live.png" className="card-img-top" alt="Live Classes" />
            <h5 className="card-title">Live Classes</h5>
          </div>

          <div className="card">
            <img src="assets/hands-on.png" className="card-img-top" alt="Hands-on Projects" />
            <h5 className="card-title">Hands-on Projects</h5>
          </div>

          <div className="card">
            <img src="assets/certification.png" className="card-img-top" alt="Certification" />
            <h5 className="card-title">Certification</h5>
          </div>

          <div className="card">
            <img src="assets/job.png" className="card-img-top" alt="Job Assistance" />
            <h5 className="card-title">Job Assistance</h5>
          </div>

        </div>
      </section>

      <section className='py-4 curriculum-section'>
        <h2 className='text-center fw-bold'>Full Stack Curriculum:</h2>

        <div className="accordion curriculum-accordion" id="accordionExample">
          <div className="accordion-item">
            <h2 className="accordion-header"><button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <img src="assets/frontend.png" alt="Front-End" className='mx-3' width={30} />Front-End
            </button>
            </h2>
            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Basics of Web Development</li>
                  <li>HTML5</li>
                  <li>CSS3</li>
                  <li>JavaScript</li>
                  <li>jQuery</li>
                  <li>Bootstrap5</li>
                  <li>Advanced JavaScript</li>
                  <li>React</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                <img src="assets/backend.png" alt="Back-End" className='mx-3' width={30} />
                Back-End and DataBase
              </button>
            </h2>
            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Node.js</li>
                  <li>Express.js</li>
                  <li>MongoDB</li>
                  <li>RestAPI</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                <img src="assets/bonus.png" alt="Bonus" className='mx-3' width={30} />
                Bonus
              </button>
            </h2>
            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div className="accordion-body">
                <ul>
                  <li>Git/GitHub</li>
                  <li>Question Answers</li>
                  <li>Quiz</li>
                  <li>Hands-on Projects</li>
                  <li>Certification</li>
                  <li>1:1 Doubt Sessions</li>
                  <li>Job Guidance & Assistance</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='py-4 faq-section'>
        <h2>Full-Stack Development <span className='faq-span'>FAQs</span></h2>

        <div className="my-2 accordion accordion-flush faq-accordion" id="accordionFlushExample">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                ❔ What skills does a full stack developer need?
              </button>
            </h2>
            <div id="flush-collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                A full stack developer must have in-depth coding experience in various programming languages responsible for front- and back-end development. Some examples include HTML, CSS, JavaScript, C++, and Java. They may also need to know how to navigate frameworks like React or Angular.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                ❔ What are the most popular languages used in full stack development?
              </button>
            </h2>
            <div id="flush-collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                There are many programming languages used in full stack development, though some are used more frequently than others. Some popular languages that contribute to efficient web development are HTML, CSS, JavaScript, PHP, Python, and Ruby on Rails.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                ❔ What are some examples of full stack development?
              </button>
            </h2>
            <div id="flush-collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Full stack developers can create a diverse range of websites. Some common examples include social media networks, e-commerce platforms, content management websites, or mobile applications.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                ❔ What's the difference between a full stack developer and a software developer?
              </button>
            </h2>
            <div id="flush-collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                A full stack developer has experience in all stages of web development, from start to finish. They are knowledgeable about multiple programming languages and frameworks for both the front- and back-end development processes. As their name suggests, software developers are involved in the creation of software programs. While each type of developer works on a different type of product, many of the skills needed for both roles overlap. Like full stack developers, software developers have knowledge of programming languages, databases, and general development principles.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                ❔ How long does it take to become a full stack developer?
              </button>
            </h2>
            <div id="flush-collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Every learner's educational path is different. It can take several months to a few years for someone to become a full stack developer. This will depend on whether they learn on their own, acquire a formal degree, or complete a bootcamp program. Regardless of the route a learner takes to becoming a full stack developer, they must practice their skills regularly, engage in various projects, and familiarize themselves with different languages and frameworks.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                ❔ What are full stack frameworks?
              </button>
            </h2>
            <div id="flush-collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
              <div className="accordion-body">
                Full stack frameworks provide developers with libraries and resources that make coding easier. With predefined structures, they can focus on the details of their web application rather than planning from scratch. Some examples of full stack frameworks include Ruby on Rails and Django, as they can be used for front- and back-end web development.
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='py-4 px-2 register-section'>
        <h3 className='mb-4 fw-bold'>Register for the Upcoming Batch Now!!</h3>

        <h4>Elevate Your Career with Web Development Certification in AGRA!</h4>

        <h4>Both ONLINE and OFFLINE Classes available.</h4>

        <h4 className='mt-4'>Don't miss this fantastic opportunity to enhance your development career and become a Full-Stack Hero.</h4>

        <h4>We are running free demo session so that you can get satisfied with us.</h4>

        <h5 className='mt-2'>*Limited seats are available, so secure your spot now!</h5>

        <h3 className='mt-3'>To register for the batch or to enquire further,<br /> please call <a href="tel:+919536899899" className='details-a'>+919536899899</a> <br /> or drop a mail to <a href="mailto:trainings@magcloudsolutions.in" className='details-a'>trainings@magcloudsolutions.in</a></h3>
      </section>

      <footer className='d-flex center'>
        <p>&copy; {new Date().getFullYear()} Mag Cloud Solutions</p>
      </footer>
    </>
  )
}

export default App;